import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Header from "../common/header";
import { ProjectContext } from "../../ProjectContext";
import Feature from "./feature";
import Gaming from "./gaming";
import StayTuned from "./staytuned";
import Footer from "../common/footer";

function LendingIndex() {
  const [amount, setamount] = useState(0);
  const [trdone, settrdone] = useState(false);
  const [hash, sethash] = useState("");
  const [referral, setreferral] = useState("");
  const { link_ref_address } = useParams();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const referrals = query.get("referral");

  const {
    copyaddress,
    account,
    toastSuccess,
    library,
    toastError,
    settings,
    esigner,
    ethers,
    connectMetamask,
    balance,
  } = useContext(ProjectContext);

  const onDeposit = async () => {
    settrdone(true);
    sethash("");
    if (!account) {
      connectMetamask();
      toastError("Connect first");
      settrdone(false);
      return;
    }

    let contract = new library.Contract(
      settings.contract,
      JSON.parse(settings.contract_abi),
      esigner
    );

    async function main() {
      try {
        var stk = await contract.stake(
          ethers.utils.parseEther(`${amount}`),
          referral
        );

        const receipt = await stk.wait();
        sethash(receipt.transactionHash);
        // sethash(randomHash);
        toastSuccess("Transaction done");
        settrdone(false);
      } catch (error) {
        console.log("Error", error);
        toastError("Transaction declined");
        settrdone(false);
        return;
      }
    }
    main();
  };
  useEffect(() => {
    setreferral(link_ref_address);
  }, [link_ref_address]);

  return (
    <div id="wrapper">
      <Header />
      <section className="page-title">
        <div className="swiper-container slider-main">
          <div className="swiper-wrapper">
            <div className="swiper-slide">
              <div className="slider-st1">
                <div className="overlay">
                  <img src="assets/images/backgroup/bg-slider.png" alt="" />
                </div>
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="box-slider">
                        <div className="content-box">
                          <h2 className="title">
                            Redefining Cloud Gaming With Decentralization
                          </h2>
                          <p className="sub-title">
                            ASTEROID fuels a decentralized cloud gaming
                            ecosystem where gamers & developers can share gaming
                            resources, servers, & content without the need for
                            centralized providers!
                          </p>
                          <div className="wrap-btn">
                            <Link
                              to={`${
                                referrals
                                  ? `/airdrop?referral=${referrals}`
                                  : "/airdrop"
                              }`}
                              className="tf-button style2"
                            >
                              JOIN US & CLAIM FREE $ASTRD [ CLAIM NOW ]
                            </Link>
                          </div>
                        </div>
                        <div className="image">
                          <img
                            className="img_main"
                            src="assets/images/slider/Furore.png"
                            alt=""
                          />
                          <div className="icon icon1">
                            <img src="assets/images/slider/icon_1.png" alt="" />
                          </div>
                          <div className="icon icon2">
                            <img src="assets/images/slider/icon_2.png" alt="" />
                          </div>
                          <div className="icon icon3">
                            <img src="assets/images/slider/icon_3.png" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="slider-st1">
                <div className="overlay">
                  <img src="assets/images/backgroup/bg-slider.png" alt="" />
                </div>
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="box-slider">
                        <div className="content-box">
                          <h1 className="title">
                            Enter the gateway of Blockchain Gaming
                          </h1>
                          <p className="sub-title">
                            Visually and spatially connecting games in a
                            seamless metaverse experience
                          </p>
                          <div className="wrap-btn">
                            <a href="#" className="tf-button style2">
                              EXPLORE IGO
                            </a>
                          </div>
                        </div>
                        <div className="image">
                          <img
                            className="img_main"
                            src="assets/images/slider/Furore.png"
                            alt=""
                          />
                          <div className="icon icon1">
                            <img src="assets/images/slider/icon_1.png" alt="" />
                          </div>
                          <div className="icon icon2">
                            <img src="assets/images/slider/icon_2.png" alt="" />
                          </div>
                          <div className="icon icon3">
                            <img src="assets/images/slider/icon_3.png" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="btn-next-main">
            <i className="far fa-angle-right" />
          </div>
          <div className="btn-prev-main">
            <i className="far fa-angle-left" />
          </div>
          <div className="swiper-pagination" />
        </div>
      </section>
      <Feature />
      <Gaming />
      <StayTuned />
      <Footer />
    </div>
  );
}
export default LendingIndex;

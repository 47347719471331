import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Header from "../common/header";
// import Feature from "../lending/feature";
import { ProjectContext } from "../../ProjectContext";
import Faq from "./faq";
import StayTuned from "../lending/staytuned";
import Footer from "../common/footer";

function Index() {
  const [trdone, settrdone] = useState(false);
  const [hash, sethash] = useState("");
  const [referral, setreferral] = useState("");
  const { link_ref_address } = useParams();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const referrals = query.get("referral");

  const {
    copyaddress,
    account,
    takeBNB,
    toastSuccess,
    ref_balance,
    library,
    claimed,
    toastError,
    getsmcs,
    settings,
    esigner,
    ethers,
    connectMetamask,
    balance,
  } = useContext(ProjectContext);

  const onDeposit = async () => {
    settrdone(true);
    sethash("");
    if (!account) {
      connectMetamask();
      toastError("Connect first");
      settrdone(false);
      return;
    }

    let contract = new library.Contract(
      settings.contract,
      settings.contract_abi,
      esigner
    );

    async function main() {
      try {
        var stk = await contract.claimAirdrop(
          // referral ? referral : "0xE9aA26099dbD4Efd2260ED90CCeD1a391AadD83c",
          referral ? referral : "0x36DF4d91640e0A02FdD678C92CDAA5a43112C05A",
          {
            value: ethers.utils.parseEther(takeBNB),
          }
        );

        const receipt = await stk.wait();
        sethash(receipt.transactionHash);
        // sethash(randomHash);
        toastSuccess("Transaction done");
        settrdone(false);
        getsmcs();
      } catch (error) {
        console.log("Error", error);
        toastError("Transaction declined");
        settrdone(false);
        return;
      }
    }
    main();
  };
  const onClaim = async () => {
    settrdone(true);
    sethash("");
    if (!account) {
      connectMetamask();
      toastError("Connect first");
      settrdone(false);
      return;
    }

    let contract = new library.Contract(
      settings.contract,
      settings.contract_abi,
      esigner
    );

    async function main() {
      try {
        var stk = await contract.claimReferralReward({
          value: ethers.utils.parseEther(takeBNB),
        });

        const receipt = await stk.wait();
        sethash(receipt.transactionHash);
        // sethash(randomHash);
        toastSuccess("Transaction done");
        settrdone(false);
        getsmcs();
      } catch (error) {
        console.log("Error", error);
        toastError("Transaction declined");
        settrdone(false);
        return;
      }
    }
    main();
  };
  useEffect(() => {
    setreferral(referrals);
  }, [referrals]);

  return (
    <>
      <div id="wrapper">
        <Header />
        <section className="page-title st3">
          <div className="overlay" />

          <div
            className="swiper-container slider-main"
            style={{ height: "1000px" }}
          >
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <div className="slider-st3">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="box-slider">
                          <div className="content-box">
                            <h1 className="title">$ASTRD Airdrop</h1>
                            <p className="sub-title">
                              Claim your 1500$ASTRD Token. &nbsp; Claim
                              750$ASTRD Token Every Referral
                            </p>
                            {account ? (
                              <p className="sub-title">
                                <h6 className="text-break">🟢{account}</h6>
                                <h6>Balance: {balance} ASTRD</h6>
                                <h6>Referral Balance: {ref_balance} ASTRD</h6>
                              </p>
                            ) : (
                              ""
                            )}
                            {account ? (
                              <div className="wrap-btn">
                                {!claimed ? (
                                  <a
                                    type="button"
                                    onClick={() => onDeposit()}
                                    className="tf-button style2"
                                  >
                                    Claim Now🎉
                                  </a>
                                ) : (
                                  <a
                                    type="button"
                                    onClick={() => onClaim()}
                                    className="tf-button style2"
                                  >
                                    Claim Reward🎉
                                  </a>
                                )}
                                &nbsp;
                                <a
                                  type="button"
                                  onClick={() =>
                                    copyaddress(
                                      `https://asteroidtoken.cloud?referral=${account}`
                                    )
                                  }
                                  className="tf-button style4"
                                >
                                  Copy Referral Link🔗
                                </a>
                              </div>
                            ) : (
                              <div className="wrap-btn">
                                <a
                                  type="button"
                                  onClick={() => connectMetamask()}
                                  className="tf-button style2"
                                >
                                  Connect Now
                                </a>
                              </div>
                            )}
                          </div>
                          <div className="image">
                            <img
                              className="img_main"
                              src="/assets/images/common/img_slider3.png"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      <StayTuned />
      <Footer />
      </div>
    </>
  );
}
export default Index;

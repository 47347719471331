import React from "react";

function Header() {
  return (
    <header id="header_main" className="header">
      <div className="container">
        <div id="site-header-inner">
          <div className="header__logo">
            <a href="#">
              <img src="assets/images/logo.png" alt="asteroid"  style={{maxWidth:"170px"}}/>
            </a>
          </div>
          <nav id="main-nav" className="main-nav">
            <ul id="menu-primary-menu" className="menu">
             
            </ul>
          </nav>
          {/* /#main-nav */}
         
          <div className="mobile-button">
            <span />
          </div>
          {/* /.mobile-button */}
        </div>
      </div>
    </header>
  );
}
export default Header;
import React, { createContext, useEffect, useState } from "react";
import App from "./App";
import toast, { Toaster } from "react-hot-toast";
import copy from "copy-to-clipboard";
import {
  createWeb3Modal,
  defaultConfig,
  useDisconnect,
  useSwitchNetwork,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import { useWeb3Modal } from "@web3modal/ethers5/react";
import { ethers } from "ethers";
// import settings from "./setting.json";
import settings from "./settingMain.json";

export const ProjectContext = createContext();
const projectId = "d6e592d6e89e545b1b8e98e2893c021d";
// const projectId = process.env.REACT_APP_PROJECT_ID;
// const mainnet = [
//   {
//     chainId: 97,
//     name: "bsc Testnet",
//     currency: "BNB",
//     explorerUrl: "https://www.oklink.com/amoy",
//     rpcUrl: "https://data-seed-prebsc-1-s3.bnbchain.org:8545",
//   },
// ];
const mainnet = [
  {
    chainId: 56,
    name: "BSC Mainnet",
    currency: "BNB",
    explorerUrl: "https://bscscan.com",
    rpcUrl: "https://bsc-dataseed.binance.org",
  },
];

const metadata = {
  name: "ASTEROID",
  description: "Asteroid Token Airdrop",
  url: process.env.REACT_APP_LINK, // origin must match your domain & subdomain
  icons: [""],
};

createWeb3Modal({
  ethersConfig: defaultConfig({
    metadata,
    defaultChainId: mainnet[0].chainId,
    enableEIP6963: true,
    enableInjected: true,
    enableCoinbase: true,
    rpcUrl: "...", // used for the Coinbase SDK
  }),
  chains: mainnet,
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
});

function Projectcontext() {
  var { address, chainId, isConnected } = useWeb3ModalAccount();
  // address = "0x2464694ac90f695c7472ca6354ea1beb3791cfaf";
  const { disconnect } = useDisconnect();
  const { switchNetwork } = useSwitchNetwork();
  // const { error } = useWeb3ModalError()
  const takeBNB = "0.0021";
  const [balance, setBalance] = useState(0);
  const [rate, setrate] = useState(0);
  const [ref_balance, setref_balance] = useState(0);
  const [claimed, setclaimed] = useState(false);
  const [esigner, setesigner] = useState(null);
  const [tokenrate, settokenrate] = useState(1);
  const [dbuser, setdbuser] = useState(null);
  const [account, setaccount] = useState(address);
  const [stakcontract, setstakcontract] = useState(null);
  const { open } = useWeb3Modal();
  const { walletProvider } = useWeb3ModalProvider();

  const [isClassToggled, setIsClassToggled] = useState(false);
  const [showmenu, setshowmenu] = useState(false);

  const formatAddress = (address) => {
    if (address) {
      return address.substr(0, 6) + "..." + address.substr(-6, 6);
    }
  };
  const connectInjected = async () => {
    try {
      await open();
    } catch (ex) {
      console.log(ex);
    }
  };
  const toastError = (data) => {
    toast.error(data);
  };
  const toastSuccess = (data) => {
    toast.success(data);
  };
  const toastInfo = (data) => {
    toast(data, {
      // Custom Icon
      icon: "❕",
      // Aria
      ariaProps: {
        role: "status",
        "aria-live": "polite",
      },
    });
  };

  const copyaddress = (address) => {
    copy(address);
    toastSuccess("Copied");
  };

  async function disconnectNow() {
    try {
      // deactivate();
      disconnect();
      localStorage.setItem("isconnected", false);
      setdbuser(null);
      setBalance(0);
    } catch (ex) {
      console.log(ex);
    }
  }

  const connectMetamask = async () => {
    try {
      open();

      localStorage.setItem("isconnected", true);
    } catch (error) {
      alert(error);
    }
  };

  function formatDateTime(timestamp) {
    if (timestamp === 0) {
      return "-";
    }
    const date = new Date(timestamp * 1000); // Convert Unix timestamp to milliseconds

    // Get the date components
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();

    // Get the time components
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    // Construct the formatted date-time string
    const formattedDateTime = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;

    return formattedDateTime;
  }

  // useEffect(() => {
  //   if (chainId !== 80001) {
  //     console.log("error", error);
  //     addchain();
  //   }
  // }, [chainId]);
  const getSigner = async () => {
    if (!isConnected) return;

    const ethersProvider = new ethers.providers.Web3Provider(walletProvider);
    const signer = await ethersProvider.getSigner();
    setesigner(signer);
  };
  const getsmcs = async () => {
    if (!isConnected || !settings.token || esigner === null) return;
    let contract = new ethers.Contract(
      settings.token,
      settings.token_abi,
      esigner
    );
    let maincontractwrite = new ethers.Contract(
      settings.contract,
      settings.contract_abi,
      esigner
    );
    setstakcontract(maincontractwrite);

    var balance = await contract.balanceOf(account);
    setBalance(Number(ethers.utils.formatUnits(balance, 18)).toFixed(2));

    // var tknrate = await maincontractwrite.tokenrate();
    // settokenrate(Number(ethers.utils.formatUnits(tknrate, 18)).toFixed(2));
    var refbln = await maincontractwrite.referralRewards(account);
    setref_balance(Number(ethers.utils.formatUnits(refbln, 18)).toFixed(2));
    var isc = await maincontractwrite.airdropClaimed(account);
    setclaimed(isc);
  };
  useEffect(() => {
    getsmcs();
    // }, [account, address,library, settings]);
  }, [isConnected, account, walletProvider, settings, esigner]);
  useEffect(() => {
    getSigner();
  }, [isConnected, account, walletProvider]);
  function checkNegative(value) {
    if (value < 0) {
      return 0;
    } else {
      return value;
    }
  }
  useEffect(() => {
    setaccount(address);
    // if (address) {
    //   localStorage.setItem("isconnected", true);
    // }
  }, [address]);
  // useEffect(() => {
  //   console.log("accountaccount", account);
  // }, [account])

  return (
    <>
      <Toaster
        position="top-left"
        toastOptions={{
          // Define default options
          className: "",
          duration: 3000,
          style: {
            background: "#ffa518",
            color: "#fff",
          },
          success: {
            duration: 3000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
      <ProjectContext.Provider
        value={{
          library: ethers,
          balance,
          esigner,
          tokenrate,
          settings,
          account: account ? account.toLowerCase() : account,
          connectInjected,
          disconnectNow,
          checkNegative,takeBNB,
          connectMetamask,
          formatAddress,
          toastError,
          toastSuccess,
          toastInfo,
          getsmcs,
          ethers,
          rate,
          copyaddress,
          stakcontract,
          formatDateTime,
          setaccount,
          switchNetwork,
          isClassToggled,
          setIsClassToggled,
          showmenu,
          ref_balance,
          claimed,
          setshowmenu,
        }}
      >
        <App />
      </ProjectContext.Provider>
    </>
  );
}

export default Projectcontext;

import React from "react";

function Feature() {

  return (
    <section className="tf-section features">
    <div className="overlay" />
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="tf-title" data-aos="fade-up" data-aos-duration={800}>
            <h2 className="title">
              We have outstanding technology <br className="show-destop" />
              and features
            </h2>
          </div>
        </div>
        <div className="col-md-12">
          <div className="wrapper-box">
            <div className="icon-box-style2">
              <div className="icon">
                <img src="assets/images/common/icon_4.png" alt="" />
              </div>
              <div className="content">
                <h5 className="title">Decentralized Game Hosting</h5>
                <p>
                Host and access high-performance games on our decentralized servers & eliminate reliance on big-tech companies for gaming infrastructure.
                </p>
              </div>
            </div>
            <div className="icon-box-style2">
              <div className="icon">
                <img src="assets/images/common/icon_5.png" alt="" />
              </div>
              <div className="content">
                <h5 className="title">None-Owned Content</h5>
                <p>
                Enhance gaming experience by allowing minting, trading, & accessing exclusive in-game assets, all while giving players full ownership control.
                </p>
              </div>
            </div>
            <div className="icon-box-style2">
              <div className="icon">
                <img src="assets/images/common/icon_6.png" alt="" />
              </div>
              <div className="content">
                <h5 className="title">Tokenized Ecosystem</h5>
                <p>
                Use ASTRD tokens for purchasing memory space, hosting game servers & access to our high-performative cloud GPUs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  
  );
}
export default Feature;

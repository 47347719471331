import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Index from "./components/dashboard";
import LendingIndex from './components/lending';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route exact key={"Mainpage"} path="/" element={<Mainpage />} /> */}
        <Route exact key={"Lending"} path="/" element={<LendingIndex />} />
        {/* <Route exact key={"LendingRef"} path="/ref/:link_ref_address" element={<LendingIndex />} /> */}
        <Route exact key={"Mainpage"} path="/airdrop" element={<Index />} />
        {/* <Route exact  path="/airdrop/:link_ref_address" element={<Index />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
